import React from "react";
import {
  Container,
  Header,
  Grid,
  Divider,
  Form,
  Button,
  Input,
  List,
  Select,
  Breadcrumb,
  Icon
} from "semantic-ui-react";
import Stepper from "../../Component/Stepper/stepper";
import DataCard from "../../Component/Card/card";
import BreadCrumbs from "../../Component/Breadcrumb/breadcrumb";
import Heading from "../../Component/Heading/heading";
import ButtonBar from "../../Component/ButtonBar/buttonbar";
import "../StepPages/stepPage.scss";
import { useHistory } from "react-router";
import { useRef } from "react";
import Updated from "../../Component/popup/updated";
import { Label } from "recharts";
import Loader from "../../Component/Loader/Loader";

const UploadDocuments = () => {
  const history = useHistory();
  const fileref = useRef();
  const [fileName, setfilename] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [docsArray, updateMyArray] = React.useState([]);
  const [services, setService] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState(null);
  const [pageload,setPageload] = React.useState(false)
  const slug = localStorage.getItem("serviceSlug");
  const service_url = `${process.env.REACT_APP_BASE_URL}/serviceCategory/${slug}`;
  const requestId = localStorage.getItem("applicationId");
  const url = `${process.env.REACT_APP_BASE_URL}/service/upload/${requestId}`;
  React.useEffect(() => {
    getServices();
    getDocumentList()
  }, []);
  // window.history.pushState(null, document.title, window.location.href);
  // window.addEventListener('popstate', function (event) {
  // window.history.pushState(null, document.title, window.location.href);
  // });

  //Function for getting uploaded Document List
  const getDocumentList = async () => {
    let application = await (
      await fetch(process.env.REACT_APP_BASE_URL + `/service/${requestId}`, {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    updateMyArray(application?.docs || []);
  }

  const getServices = async () => {
    const service = await (await fetch(service_url, { method: "GET" })).json();
    let application = await (
      await fetch(
        `${process.env.REACT_APP_BASE_URL}/service/${requestId}`,
        {
          method: "GET"
        })).json();
    let subCatdata = service.data.serviceDetail.find(e => e.name === application.serviceDetail ? application.serviceDetail : localStorage.getItem('subCatId'));
    const serviceData = {
      reqDocs: subCatdata.reqDocs
    };
    setService(serviceData);
    localStorage.setItem("subCatId", subCatdata._id);
  };

  if (!services) {
    return <div />;
  }

  const uploadWithFormData = async (event) => {
    event.preventDefault();
    if (!fileName) {
      throw ("Select a file first");
    }


    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("name", fileName);

    setPageload(true)
    const result = await (
      await fetch(url, {
        method: "PUT",
        headers: { "x-access-token": localStorage.getItem("token") },
        body: formData,
      })
    ).json();
setPageload(false)
    if (result.status === 1) {
      setOpen(true);
      setMsg(fileName + " saved ");
      updateMyArray(result?.data?.application?.docs || [])
      // updateMyArray((oldArray) => [...oldArray, fileName]);
    }else{
      alert(result.msg)
    }
  };
  const handleSubmitForm = (event) => {
    if (docsArray.length === services.reqDocs.length) history.push("/book");
    else{
      alert("Please add complete documents.")
    }
  };

  const generateLink = async (key) => {
    const jsonPostData = {
      key: key,
    };
    const url = process.env.REACT_APP_BASE_URL + `/download`;
    setPageload(true)
    const resu = await (
      await fetch(url, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonPostData),
      })
    ).json();
    setPageload(false)
    if (resu.status === 1) {
      window.open(resu.data, "_blank")
    }
  };

  const removeData = async (url, data, token) => {
    setPageload(true)
    const res = await (await fetch(url, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "x-access-token": token,
      },
      body: JSON.stringify(data)
    })).json();
    setPageload(false)
    return res;
  }

  const removeFile = async (documentId, index) => {
    let token = localStorage.getItem("token");
    setPageload(true)
    const url = process.env.REACT_APP_BASE_URL + `/service/remove-doc`;
    let request = { applicationId: requestId, documentId }
    const res = await removeData(url, request, token);
    setPageload(false)
    if (res.status === 1) {
      let temp = [...docsArray]
      temp.splice(index, 1)
      updateMyArray(temp);
    } else {
      alert(res.msg);
    }
  }

  return (
    <main>
      <div className="apply-section">
        {/* <BreadCrumbs
          section={[
            { key: "home", content: "Home", link: true },
            { key: "apply", content: "Apply Now", active: true },
          ]}
        /> */}
        <Breadcrumb>
          <Breadcrumb.Section href="/">Home</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>
            Apply Now
          </Breadcrumb.Section>
        </Breadcrumb>
        <Heading />
        <Grid className="data" columns="2" stackable="tablet">
          <Grid.Column width={11}>
            <Container className="stepper-container">
              <Stepper />
              <Divider />
              <div className="upload-form">
                <Grid column="3" stackable="tablet" centered>
                  <Grid.Row className="upload-container">
                    <Grid.Column width={8}>
                      <Form.Field>
                        <label for="docs">Choose a document</label>
                        <Select
                          placeholder="Chose a document"
                          onChange={(e, { value }) => setfilename(value)}
                          options={services.reqDocs.map((ele, index) => ({
                            key: index,
                            value: ele,
                            text: ele,
                          }))}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Form.Field>
                        <label>Scan and upload documents</label>
                        <input
                          ref={fileref}
                          type="file"
                          hidden
                          onChange={(e) => uploadWithFormData(e)}
                        />
                        <div className="file" style={{ cursor: 'pointer' }}>
                          <label onClick={() => fileref.current.click()} for="file-btn" style={{ cursor: 'pointer' }}>
                            Click to Upload file from your device
                          </label>

                        </div>
                      </Form.Field>
                      <p style={{ margin: '15px', textDecoration: 'underline' }}>Format: JPG/PNG</p>
                    </Grid.Column>
                    {/* <Grid.Column width={2}> */}
                    {/* <Button className='btn-upload' onClick={uploadWithFormData}>UPLOAD</Button> */}
                    {/* </Grid.Column> */}
                  </Grid.Row>
                </Grid>
                <div className="document-list">
                  <label>Documents Required</label>
                  <List>
                    {services.reqDocs.map((ele) => (
                      <List.Item>
                        <List.Icon name="square" />
                        <List.Content>{ele}</List.Content>
                      </List.Item>
                    ))}
                  </List>
                </div>
                <div className="document-list">
                  <label>Documents Submitted</label>
                  {docsArray.length === 0 ? (
                    <List>
                      <List.Item>
                        <List.Icon name="square" />
                        <List.Content>No Data Uploaded</List.Content>

                      </List.Item>
                    </List>
                  ) : (
                    <List>

                      {docsArray.map((ele, index) => (
                        <List.Item style={{ display: "flex", justifyContent: "space-between" }}>
                          <div style={{ display: "flex" }}>
                            <List.Icon name="square" />
                            <List.Content style={{ cursor: "pointer" }} onClick={() => generateLink(ele.key)}>{ele.name}</List.Content>
                          </div>
                          <div onClick={() => removeFile(ele._id, index)}><Icon style={{ cursor: "pointer" }} name="times" /></div>
                        </List.Item>
                      ))}
                    </List>
                  )}
                </div>
              </div>
              <div className="upload_save" style={{ textAlign: "center" }}>
                <button
                  className="same-btn"
                  onClick={handleSubmitForm}
                  value="submit"
                >
                  Save
                </button>
              </div>
            </Container>
          </Grid.Column>
          <Grid.Column width={5}>
            <DataCard />
          </Grid.Column>
        </Grid>
      </div>
      {/* <ButtonBar /> */}
      <Updated open={open} msg={msg} onClose={() => setOpen(false)} />
                        <Loader open={pageload} />
    </main>
  );
};
export default UploadDocuments;

import React from 'react'
import './loader.css'
export default function Loader({type,  open = false }) {
    return (
        <React.Fragment>
             {type === "btn" && open && <div className="btn-loader"></div>}
            {!type && open && <div className="lg-loader-box"><div className="lg-loader"></div></div>}
        </React.Fragment>
    )
}

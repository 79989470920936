import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './Sass/index.scss';
import 'semantic-ui-css/semantic.min.css';

(() => {
  console.log('webpack worked')
})()
ReactDOM.render(
    <App />,
  document.getElementById('root')
);


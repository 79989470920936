import React, { useState } from "react";
import { Button, Checkbox, Label, Form, Message, Icon } from "semantic-ui-react";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import Loader from "../Loader/Loader";

const Login1 = () => {
  const history = useHistory();

  const [gvalue, setGvalue] = React.useState(false);

  const url = `${process.env.REACT_APP_BASE_URL}/login`;
  const [email, setEmail] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [msg, setMsg] = React.useState(null);
  const [eye, setEye] = React.useState(false);
  const [pageLoad,setPageLoad] = useState(false) 
 
  const handleSubmitForm = async (event) => {
    event.preventDefault();

    if (gvalue === true) {
      const jsonPostData = {
        email: email,
        password: password,
      };

      const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      const test = reg.test(jsonPostData.email);
      if (!test) {
       return setMsg("Please enter  valid email")
      }
      else if(!jsonPostData.password){
        return setMsg("Please enter password")
      }

      setPageLoad(true)
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonPostData),
      });
      setPageLoad(false)
      const data = await res.json();
      if (data.status == 1 && data.token) {
        localStorage.setItem("token", data.token);

        let idData = await (
          await fetch(`${process.env.REACT_APP_BASE_URL}/users`, {
            method: "GET",
            headers: {
              "x-access-token": localStorage.getItem("token"),
            },
          })
        ).json();
        idData = idData.data;
        localStorage.setItem("id", idData._id);
        localStorage.setItem("name", idData.name);
        if (idData?.isAdmin === false) history.push("/");
        else history.push("/admin");
      }
      else{
        setMsg("Invalid Email/Password")
      }
    }else{
      setMsg("Please select - I am not a Robot")
    }
  };

  const gCaptchaChange = async (value) => {
    const url = `${process.env.REACT_APP_BASE_URL}/admin/g_server`;
    const pData = {
      humanKey: value,
    };
    const gReq = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(pData),
    });

    const jsonD = await gReq.json();

    if (jsonD.success === true) {
      setGvalue(true);
      setMsg(null)
    }
  };

  return (
    <>
      <div
        className="loginx"
        style={{
          background: "url(/Assets/images/login.png)",
          height: "100vh",
          backgroundSize: "cover",
        }}
      >
        <Form className="loginx_form" onSubmit={handleSubmitForm}>
          <h3>Login into your account</h3>
          <Form.Field>
            <label>Email</label>
            <input
              type="text"
              onChange={(event) => {setEmail(event.target.value); setMsg(null)}}
              name="Email"
              placeholder="Enter Email"
              
            />
          
          </Form.Field>
          <Form.Field>
            <label> Password</label>
            <input
              type={eye?"text":"password"}
              onChange={(event) => {setPassword(event.target.value); setMsg(null)}}
              name="password"
              style={{position:'relative'}}
              placeholder="Enter password"
            />
                <Icon style={{position:'absolute',right:'4vw',marginTop:'0.75em',width:''}} color="grey" name='eye' onClick={()=>setEye(prevCheck => !prevCheck)}/>

          
          </Form.Field>

          <Form.Field className="check">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
              onChange={gCaptchaChange}
            />
          </Form.Field>

          {/*
          <Form.Field className="check">
            <Checkbox label="I’m not a Robot" required={true} readOnly={false} />
          </Form.Field>
          */}
          <span class="fgt1">
            <a
              href="javascript:void(0);"
              onClick={() => history.push("/forgot")}
            >
              Forgot password?
            </a>
          </span>

          <button className="form-btn" type="submit">
            Submit
          </button>
          <span class="fgt2">
            <a
              href="javascript:void(0);"
              onClick={() => history.push("/register")}
            >
              New to Epro? Sign Up here
            </a>
            <br/>
            <br/>
            {msg ? (
          <Label as="a" className="Rejected">
            {msg}
          </Label>
        ) : (
          <div></div>
        )}
          </span>
        </Form>
         <Loader open={pageLoad} />
      </div>
    </>
  );
};

export default Login1;

import _ from 'lodash';
import React from 'react';
import { Form, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from '../Loader/Loader';

const initialValues = {
    new_password: "",
    confirm_password: "",
    otp: ""
};

const Reset = () => {
    const url = `${process.env.REACT_APP_BASE_URL}/forget/password`;
    const history = useHistory();
    const [eye, setEye] = React.useState({ password: false, confirmPassword: false });
    const [error, setError] = React.useState();
    const [pageLoad,setPageLoad] = React.useState(false) 
 
    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({
            otp: Yup.string()
                .required("Please enter OTP."),
            new_password: Yup.string()
                .min(6, "Password should be of minimum 6 characters.")
                .required("Please enter password."),
            confirm_password: Yup.string()
                .required("Please enter Re-Enter Password."),
        }),
        onSubmit: async (values) => {
            try {
                if (values.new_password !== values.confirm_password) {
                    return setError("Password does not match with Re-Enter Password.")
                }
                setPageLoad(true)
                setError("")
                let jsonPostData = {};
                if (Object.keys(localStorage).includes('email'))
                    jsonPostData = {
                        'email': localStorage.getItem('email'),
                        'new_password': values.new_password,
                        'confirm_password': values.confirm_password,
                        'otp': parseInt(values.otp)
                    }
                if (Object.keys(localStorage).includes('phone'))
                    jsonPostData = {
                        'phone': localStorage.getItem('phone'),
                        'new_password': values.new_password,
                        'confirm_password': values.confirm_password,
                        'otp': parseInt(values.otp)
                    }
                const res = await (await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(jsonPostData)
                })).json();
                setPageLoad(false)
                if (res.status == 1) {
                    alert(res.msg);
                    localStorage.removeItem("email");
                    localStorage.removeItem("phone");
                    history.push('/login');
                } else {
                    alert(res.msg);
                }

            } catch (error) {

            }
        }
    });

    return (
        <>
            <div
                className="loginx"
                style={{
                    background: "url(/Assets/images/login.png)",
                    height: "100vh",
                    backgroundSize: "cover",
                }}
            >
                <Form className="loginx_form2" onSubmit={formik.handleSubmit}>
                    <h3>Reset Your Password</h3>
                    <Form.Field>
                        <label>Password</label>
                        <input
                            type={eye.password ? "text" : "password"}
                            onChange={formik.handleChange}
                            name="new_password"
                            id="new_password"
                            style={{ position: 'relative' }}
                            maxLength="15"
                            placeholder="Enter your new Password"
                        />
                        <Icon style={{ position: 'absolute', right: '4vw', marginTop: '0.75em', cursor: "pointer" }} color="grey" name={eye.password ? 'eye' : 'eye slash'} onClick={() => setEye(prev => { return { ...prev, password: !prev.password } })} />
                        {formik.touched.new_password && formik.errors.new_password ? (
                            <div className="error">
                                <p>{formik.errors.new_password}</p>
                            </div>
                        ) : null}
                    </Form.Field>
                    <Form.Field>
                        <label>Re-Enter Password</label>
                        <input
                            type={eye.confirmPassword ? "text" : "password"}
                            onChange={formik.handleChange}
                            name="confirm_password"
                            id="confirm_password"
                            style={{ position: 'relative' }}
                            maxLength="15"
                            placeholder="Confirm your password"
                        />
                        <Icon style={{ position: 'absolute', right: '4vw', marginTop: '0.75em', cursor: "pointer" }} color="grey" name={eye.confirmPassword ? 'eye' : 'eye slash'} onClick={() => setEye(prev => { return { ...prev, confirmPassword: !prev.confirmPassword } })} />
                        {formik.touched.confirm_password && formik.errors.confirm_password ? (
                            <div className="error">
                                <p>{formik.errors.confirm_password}</p>
                            </div>
                        ) : error ? <div className="error">
                            <p>{error}</p>
                        </div> : null}
                    </Form.Field>
                    <Form.Field>
                        <label>OTP</label>
                        <input maxLength={6} id="otp" placeholder="Enter OTP" name="otp" onChange={formik.handleChange} />
                        {formik.touched.otp && formik.errors.otp ? (
                            <div className="error">
                                <p>{formik.errors.otp}</p>
                            </div>
                        ) : null}
                    </Form.Field>
                    {/*
          <Form.Field>
            <label>Enter OTP</label>
          <div className="otp">
          
            <input style={{width:'70px'}}/>
            <input style={{width:'70px'}}/>
            <input style={{width:'70px'}}/>
            <input style={{width:'70px'}}/>
            <input style={{width:'70px'}}/>
          
          </div>
          
          </Form.Field>
        
          <span class="fgt1">
             <a href="#">Resend in 01:45</a>
          </span>
          */}
                    <h4>(OTP will be sent on your registered email ID)</h4>

                    <button className="form-btn" type="submit">Submit</button>
                </Form>
                <Loader open={pageLoad} />
            </div>

        </>
    );
}

export default Reset;
import moment from "moment";
import React from "react";
import { useHistory } from "react-router";
import {
  Grid,
  Divider,
  Form,
  Message,
  Container,
  Input,
  Label,
  Radio,
  Dropdown,
  Breadcrumb
} from "semantic-ui-react";
import DataCard from "../../Component/Card/card";
import Heading from "../../Component/Heading/heading";
import Loader from "../../Component/Loader/Loader";
import Stepper from "../../Component/Stepper/stepper";
import "../StepPages/stepPage.scss";

function FillPage() {

  const history = useHistory();
  // window.history.pushState(null, document.title, window.location.href);
  // window.addEventListener('popstate', function (event){
  //     window.history.pushState(null, document.title,  window.location.href);
  // });
  if (!localStorage.getItem("token") && !localStorage.getItem("id")) {
    history.push("/login");
  }

  const [user, setUser] = React.useState(null);
  const [name, setName] = React.useState(null);
  const [dob, setDob] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [alias, setAlias] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [mobile, setMobile] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [city, setCity] = React.useState(null);
  const [country, setCountry] = React.useState(null);
  const [pincode, setPincode] = React.useState(null);
  const [msg, setmsg] = React.useState(null);
  const [medCenter, setCenter] = React.useState(null);

  const [stateList, setStateList] = React.useState([]);
  const [cityList, setCityList] = React.useState([]);
  const [countryList, setCountryList] = React.useState([]);
  const [pageLoad, setPagelaod] = React.useState(true)

  React.useEffect(() => {
    getUser();
    getCountry()
  }, []);

  async function getCountry() {
    const res = await (await fetch(process.env.REACT_APP_BASE_URL + '/masterData/getCountry')).json();
    const country = res.data.sort().map((val) => ({
      text: val,
      value: val,
      key: val,
    }))
    setCountryList(country)
  }

  async function getState(country) {
    const res = await (await fetch(process.env.REACT_APP_BASE_URL + `/masterData/getStates?country=${country}`, {
      method: 'GET',
    })).json();
    const state = res.data.sort().map((val) => ({
      text: val,
      value: val,
      key: val,
    }))
    setStateList(state)
  }

  async function getCity(state) {
    const res = await (await fetch(process.env.REACT_APP_BASE_URL + `/masterData/getCities?state=${state}`, {
      method: 'GET',
    })).json();

    const city = res.data.sort().map((val) => ({
      text: val,
      value: val,
      key: val,
    }))
    setCityList(city)
  }

  const getUser = async () => {
    const id = localStorage.getItem("id");
    let user = await (
      await fetch(`${process.env.REACT_APP_BASE_URL}/users/${id}`, {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();

    const applicationId = localStorage.getItem("applicationId");
    let application = await (
      await fetch(process.env.REACT_APP_BASE_URL + `/service/${applicationId}`, {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      })
    ).json();
    setPagelaod(false)

    user = user.data;
    setPincode(application?.otherAddress?.pincode || user?.address?.pincode)
    setMobile(application?.mobile || user?.phone)
    setEmail(application?.email || user?.email)
    setName(application?.name || user?.name)
    setDob(new Date(application?.dob ? moment(application?.dob).format("YYYY-MM-DD") : user?.dob ? moment(user?.dob).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")));
    setAlias(application?.otherAddress?.alias)
    setCountry(application?.otherAddress?.country || user?.address?.country)
    setState(application?.otherAddress?.state || user?.address?.state)
    setCity(application?.otherAddress?.city || user?.address?.city)
    setCenter(application?.center)
    setUser(user || []);

    if (application?.otherAddress?.country || user?.address?.country) {
      getState(application?.otherAddress?.country || user?.address?.country)
    }
    if (application?.otherAddress?.state || user?.address?.state) {
      getCity(application?.otherAddress?.state || user?.address?.state)
    }

  };

  const handleTypeChange = (event, { value }) => {
    setType(value);
  };
  const handleAliasChange = (event, { value }) => {
    setAlias(value);
  };
  const center = ["Al Karama Medical Fitness Center",
    "Bur Dubai Medical Fitness Center",
    "Al-Muhaisnah Medical Fitness Center",
    "Al Quoz Mall Medical Fitness  Center",
    "Al Yalayis Medical Fitness Center",
    "Al Nahda Occupational Health Screening & Medical Fitness Center",
    "Al Garhoud Medical Fitness Center"];
  const subOpt = center.map((e) => ({
    text: e,
    value: e,
    key: e,
  }))

  const slug = localStorage.getItem("serviceSlug");
  const requestId = localStorage.getItem("applicationId");
  const url = `${process.env.REACT_APP_BASE_URL}/service/fill/${requestId}`;

  const handleSubmitForm = async (event) => {
    event.preventDefault();

    if (
      !(name || user.name) ||
      !dob ||
      !alias ||
      !(email || user.email) ||
      !(mobile || user.phone) ||
      !(state || user.address.state) ||
      !(city || user.address.city) ||
      !(pincode || user.address.pincode) ||
      !(country || user.address.country)
    ) {
      setmsg("Please fill all the details");
      return;
    } else {
      setmsg(null);
    }

    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const test = reg.test(email);
    if (!test) {
      return setmsg("Please enter  valid email.")
    }
    else if (!mobile || mobile.toString().length < 9) {
      return setmsg("Please enter  valid mobile number.")
    }
    else if (!pincode || pincode.toString().length < 6) {
      return setmsg("Please enter  valid pincode.")
    }

    const jsonPostData = {
      name: name ? name : user.name,
      dob: dob,
      center: medCenter,
      // type: type,
      email: email ? email : user.email,
      mobile: mobile ? mobile : user.phone,
      address: {
        alias: alias ? alias : user.address.alias,
        // addressLineOne: lineOne ? lineOne : user.address.addressLineOne,
        // addressLineTwo: lineTwo ? lineTwo : user.address.addressLineTwo,
        state: state ? state : user.address.state,
        city: city ? city : user.address.city,
        pincode: pincode ? pincode : user.address.pincode,
        country: country ? country : user.address.country,
      },
    };

    setPagelaod(true)

    // return
    const result = await (
      await fetch(url, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(jsonPostData),
      })
    ).json();
    setPagelaod(false)
    history.push("/mode");
  };

  if (!user) {
    return <Loader open={pageLoad} />;
  }

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = mm + '/' + dd + '/' + yyyy;


  return (
    <main>
      <div className="fill-section">
        <Breadcrumb>
          <Breadcrumb.Section href="/">Home</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>
            Apply Now
          </Breadcrumb.Section>
        </Breadcrumb>

        <Heading />
        <Grid className="data" columns="2" stackable="tablet">
          <Grid.Column width={11}>
            <Container className="stepper-container">
              <Stepper />
              <Divider />
              <div className="form">
                <Message>
                  <label>
                    <i className="close icon"></i>
                  </label>
                  <label className="alert">
                    Please fill all the mandatory fields in order to proceed and
                    complete the application request
                  </label>
                </Message>
                <Grid columns="2" stackable="tablet">
                  <Grid.Row>
                    {/* <Grid.Column width={2} className="radio-group">
                      <Radio
                        label="Self"
                        name="type"
                        id="self"
                        value="self"
                        checked={type === "self"}
                        onChange={handleTypeChange}
                        className="radio-item"
                        name="choice"
                      />
                    </Grid.Column>
                    <Grid.Column className="radio-group">
                      <Radio
                        label="Other"
                        name="type"
                        id="other"
                        value="other"
                        checked={type === "other"}
                        onChange={handleTypeChange}
                        className="radio-item"
                        name="choice"
                      />
                    </Grid.Column> */}
                    <Grid.Column>
                      <Form.Field>
                        <label>Name *</label>
                        <Input
                          onChange={(event) => setName(event.target.value)}
                          name="name"
                          defaultValue={name}
                          placeholder="Enter name"
                          maxLength={30}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label for="dob">Date of Birth*</label>
                        <Input
                          type="date"
                          onChange={(event) => setDob(event.target.value)}
                          id="dob"
                          name="dob"
                          max={moment().format("YYYY-MM-DD")}
                          defaultValue={moment(dob).format("YYYY-MM-DD")}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column className="radio-group" width={2}>
                      <Radio
                        label="Home"
                        name="Address"
                        value="Home"
                        checked={alias === "Home"}
                        onChange={handleAliasChange}
                        id="Address1"
                        className="radio-item"
                      />
                    </Grid.Column>
                    <Grid.Column className="radio-group" width={14}>
                      <Radio
                        label="Office"
                        name="Address"
                        value="Office"
                        checked={alias === "Office"}
                        onChange={handleAliasChange}
                        id="Address2"
                        className="radio-item"
                      />
                    </Grid.Column>
                    {slug === "medical-services" ? <Grid.Column width={16}>
                      <Form.Field style={{ marginBottom: '1rem' }}>
                        <label>Medical Center*</label>
                        <Dropdown
                          className="golu"
                          placeholder="Choose Category"
                          options={subOpt}
                          icon="angle down"
                          onChange={(e, i) => {
                            const val = i.value;
                            setCenter(val);
                          }}
                          defaultValue={medCenter}
                        ></Dropdown>
                      </Form.Field>
                    </Grid.Column>
                      : <></>}
                    <Grid.Column>
                      <Form.Field>
                        <label>Email*</label>
                        <Input
                          onChange={(event) => setEmail(event.target.value)}
                          name="email"
                          value={email}
                          placeholder="Enter Email*"
                          maxLength={30}

                        />
                      </Form.Field>
                    </Grid.Column>

                    <Grid.Column>
                      <Form.Field>
                        <label>Mobile*</label>
                        <Input
                          onChange={(event) => setMobile(event.target.value && event.target.value.substring(0, 11))}
                          name="mobile"
                          value={mobile}
                          placeholder="Enter Mobile*"
                          type="number"
                        />
                      </Form.Field>
                    </Grid.Column>

                    <Grid.Column>
                      <Form.Field>
                        <label>Country*</label>
                        <Dropdown
                          style={{ display: "flex", justifyContent: "space-between", padding: '5px 10px', alignItems: "center" }}
                          placeholder="Select country"
                          options={countryList}
                          icon="angle down"
                          fluid
                          selection = {true}
                          search={true}
                          onChange={(e, txt) => { getState(txt.value); setCountry(txt.value) }}
                          defaultValue={country}
                        ></Dropdown>
                      </Form.Field>
                    </Grid.Column>

                    <Grid.Column>
                      <Form.Field>
                        <label>State*</label>
                        {/* <Input
                          onChange={(event) => setState(event.target.value)}
                          name="state"
                          defaultValue={user.address && user.address.state}
                          placeholder="Select state"
                        /> */}
                        <Dropdown
                          style={{ display: "flex", justifyContent: "space-between", padding: '5px 10px', alignItems: "center" }}
                          placeholder="Select state"
                          options={stateList}
                          icon="angle down"
                          fluid
                          selection = {true}
                          search={true}
                          onChange={(e, txt) => { getCity(txt.value); setState(txt.value) }}
                          defaultValue={state}
                        ></Dropdown>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>City*</label>
                        <Dropdown
                          style={{ display: "flex", justifyContent: "space-between", padding: '5px 10px', alignItems: "center" }}
                          placeholder="Select city"
                          options={cityList}
                          icon="angle down"
                          fluid
                          selection = {true}
                          search={true}
                          onChange={(e, txt) => { setCity(txt.value) }}
                          defaultValue={city}
                        ></Dropdown>
                      </Form.Field>
                    </Grid.Column>

                    <Grid.Column>
                      <Form.Field>
                        <label>Pincode*</label>
                        <Input
                          type="number"
                          onChange={(event) => setPincode(event.target.value && event.target.value.substring(0, 6))}
                          name="pincode"
                          value={pincode}
                          placeholder="Enter Pincode"
                        />
                      </Form.Field>
                    </Grid.Column>


                  </Grid.Row>
                </Grid>
              </div>
              {msg ? (
                <Label as="a" className="Rejected">
                  {msg}
                </Label>
              ) : (
                <div></div>
              )}
              <div className="upload_save" style={{ textAlign: "center" }}>
                <button
                  className="same-btn"
                  onClick={handleSubmitForm}
                  value="submit"
                >
                  Save
                </button>
              </div>
            </Container>
          </Grid.Column>
          <Grid.Column width={5}>
            <DataCard />
          </Grid.Column>
        </Grid>
      </div>
      {/* <ButtonBar /> */}
      <Loader open={pageLoad} />
    </main>
  );
}
export default FillPage;

import moment from "moment";
import React, { useEffect } from "react";
import {
    Grid,
    Form,
    Input,
    Modal,
    Label,
    Dropdown
} from "semantic-ui-react";
import '../../Pages/StepPages/stepPage.scss';
import Loader from "../Loader/Loader";

function exampleVerify(state, action) {
    switch (action.type) {
        case "close":
            return { open: false };
        case "open":
            return { open: true, size: action.size };
        default:
            throw new Error("Unsupported action...");
    }
}

const User_Modal_Edit = (user) => {
    const [name, setName] = React.useState(null);
    const [phone, setPhone] = React.useState(user?.phone);
    const [lineOne, setLineOne] = React.useState(null);
    const [lineTwo, setLineTwo] = React.useState(user.addressLineTwo);
    const [addstate, setState] = React.useState(null);
    const [city, setCity] = React.useState(null);
    const [country, setCountry] = React.useState(null);
    const [pincode, setPincode] = React.useState(null);
    const [msg, setMsg] = React.useState(null);
    const [dob, setDob] = React.useState(null);

    const [stateList, setStateList] = React.useState([]);
    const [cityList, setCityList] = React.useState([]);
    const [countryList, setCountryList] = React.useState([]);

    const [pageload, setPageload] = React.useState(false)
    useEffect(() => {
        getCountry()
        getState(user?.country)
        getCity(user?.state)

    }, [user])

    async function getCountry() {
        const res = await (await fetch(process.env.REACT_APP_BASE_URL + '/masterData/getCountry')).json();
        const country = res.data.sort().map((val) => ({
            text: val,
            value: val,
            key: val,
        }))
        setCountryList(country)
    }

    async function getState(country) {
        const res = await (await fetch(process.env.REACT_APP_BASE_URL + `/masterData/getStates?country=${country}`, {
            method: 'GET',
        })).json();
        const state = res.data.sort().map((val) => ({
            text: val,
            value: val,
            key: val,
        }))
        setStateList(state)
    }

    async function getCity(state) {
        const res = await (await fetch(process.env.REACT_APP_BASE_URL + `/masterData/getCities?state=${state}`, {
            method: 'GET',
        })).json();

        const city = res.data.sort().map((val) => ({
            text: val,
            value: val,
            key: val,
        }))
        setCityList(city)
    }

    const submitUser = async (id) => {
        if (!(phone ? phone : user.phone).toString().match(/^[0-9]{8,10}$/)) {
            setMsg("Invalid Phone");
            return;
        }
        const url = `${process.env.REACT_APP_BASE_URL}/users/${id}`;
        const jsonPostData = {
            "name": name ? name : user.name,
            "phone": phone ? phone : user.phone,
            dob: dob ? dob : user?.dob,
            "address":
            {
                "addressLineOne": lineOne ? lineOne : user.addressLineOne,
                "addressLineTwo": lineTwo,
                "state": addstate ? addstate : user.state,
                "city": city ? city : user.city,
                "pincode": pincode ? pincode : user.pincode,
                "country": country ? country : user.country
            }
        }

        setPageload(true)
        const result = await (
            await fetch(url, {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-access-token": localStorage.getItem("token")
                },
                body: JSON.stringify(jsonPostData),
            })
        ).json();

        setPageload(false)
        /* alert(result.msg); */
        if (result.status === 1) {
            user.doRefresh()
            dispatch({ type: "close" });
            setMsg("Saved Successfully");
            window.location.reload(false);

        }
        else {
            setMsg(result.msg)
        }
    };

    const [state, dispatch] = React.useReducer(exampleVerify, {
        open: false,
        size: undefined,
    });
    const { open, size } = state;

    return (
        <>
            <p onClick={() => dispatch({ type: "open", size: "small" })}>Edit Profile</p>
            <Modal
                size={size}
                open={open}
                style={{willChange : "initial"}}
                onClose={() => { dispatch({ type: "close" }); setMsg(null) }}>
                <Modal.Header>
                    <div className="accept_heading">Edit Profile</div>
                </Modal.Header>
                <Modal.Content>
                    <div className="faq_content">
                        <Form>

                            <Grid columns="2" stackable='tablet'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Field
                                            style={{ marginLeft: 0 }}
                                            control={Input}
                                            label='Name *'
                                            maxLength="25"
                                            onChange={(event) => setName(event.target.value)} name='name'
                                            defaultValue={user.name}

                                        />
                                    </Grid.Column>


                                    <Grid.Column>
                                        <Form.Field
                                            type="number"
                                            control={Input}
                                            label='Number *'
                                            maxLength="11"
                                            value={(phone && phone.toString().substring(0, 10))}
                                            onChange={(event) => setPhone(event.target.value)} name='phone'
                                            defaultValue={user.phone}

                                        />

                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field style={{ marginTop: '15px' }}>
                                            <label for="dob">Date of Birth*</label>
                                            <Input
                                                type="date"
                                                onChange={(event) => setDob(event.target.value)}
                                                id="dob"
                                                name="dob"
                                                max={moment().format("YYYY-MM-DD")}
                                                defaultValue={moment(user?.dob).format("YYYY-MM-DD")}
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Form.Field style={{ marginTop: '15px' }}>
                                            <label>Address Line 1 *</label>
                                            <Input onChange={(event) => setLineOne(event.target.value)} name='lineOne' maxLength="30" defaultValue={user.addressLineOne} placeholder='Enter address line 1' />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column style={{ marginTop: '15px' }}>
                                        <Form.Field>
                                            <label>Address Line 2</label>
                                            <Input onChange={(event) => setLineTwo(event.target.value)} name='lineTwo' maxLength="30" defaultValue={user.addressLineTwo} placeholder='Enter address line 2' />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Form.Field style={{ marginTop: '15px' }}>
                                            <label>Country *</label>
                                            <Dropdown
                                                style={{ display: "flex", justifyContent: "space-between", padding: '5px 10px', alignItems: "center", marginLeft: 0 }}
                                                placeholder="Select country"
                                                options={countryList}
                                                icon="angle down"
                                                fluid
                                                selection = {true}
                                                search={true}
                                                onChange={(e, txt) => { getState(txt.value); setCountry(txt.value) }}
                                                defaultValue={user?.country}
                                            ></Dropdown>

                                            {/* <label>City *</label>
                                            <Input onChange={(event) => setCity(event.target.value)} maxLength="20" name='city' defaultValue={user.city} placeholder='Select city' /> */}
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Form.Field style={{ marginTop: '15px' }}>
                                            <label>State *</label>
                                            <Dropdown
                                                style={{ display: "flex", justifyContent: "space-between", padding: '5px 10px', alignItems: "center", marginLeft: 0 }}
                                                placeholder="Select state"
                                                options={stateList}
                                                icon="angle down"
                                                fluid
                                                selection = {true}
                                                search={true}
                                                onChange={(e, txt) => { getCity(txt.value); setState(txt.value) }}
                                                defaultValue={user?.state}
                                            ></Dropdown>
                                            {/* <Input onChange={(event) => setState(event.target.value)} maxLength="20" name='state' defaultValue={user.state} placeholder='Select state' /> */}
                                        </Form.Field>
                                    </Grid.Column>


                                    <Grid.Column>
                                        <Form.Field style={{ marginTop: '15px' }}>
                                            <label>City*</label>
                                            <Dropdown
                                                style={{ display: "flex", justifyContent: "space-between", padding: '5px 10px', alignItems: "center", marginLeft: 0 }}
                                                placeholder="Select city"
                                                options={cityList}
                                                icon="angle down"
                                                fluid
                                                selection = {true}
                                                search={true}
                                                onChange={(e, txt) => { setCity(txt.value) }}
                                                defaultValue={user?.city}
                                            ></Dropdown>
                                            {/* <Input onChange={(event) => setCountry(event.target.value)} name='country' defaultValue={user.country} placeholder='Select country' /> */}
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column>
                                        <Form.Field style={{ marginTop: '15px' }}>
                                            <label>PIN Code *</label>
                                            <Input type="number" onChange={(event) => setPincode(event.target.value)} name='pincode' defaultValue={user.pincode} placeholder='Enter PIN code' />
                                        </Form.Field>
                                    </Grid.Column>


                                </Grid.Row>
                            </Grid>
                        </Form>
                    </div>
                </Modal.Content>
                <Modal.Description>
                    <div className="accept_bottom">
                        <button
                            color="black"
                            className="same-btn"
                            onClick={() => { dispatch({ type: "close" }); setMsg(null) }}
                        >
                            CANCEL
                        </button>
                        <button
                            color="black"
                            className="same-btn"

                            onClick={() => submitUser(user.id)}
                        >
                
                                SAVE
                        </button>
                        <br />
                        <br />
                    </div>
                    {msg ? (
                        <Label as="a" className="Rejected">
                            {msg}
                        </Label>
                    ) : (
                        <div></div>
                    )}
                 <Loader  open={pageload} />
                </Modal.Description>
            </Modal>
           
        </>
    );
};
export default User_Modal_Edit;
